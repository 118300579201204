import { graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import { Helmet } from "react-helmet"

import { CaseStudyCard } from "../components/Card/types"
import EmptyPage from "../components/EmptyPage"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import SectionTitle from "../components/SectionTitle"
import {
  createStaticContent,
  makeStaticPageTitle,
  transformToArray,
  transformToMap,
} from "../data/transform"

export const query = graphql`
  query($product: String!, $recordId: String!) {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    product: airtable(
      table: { eq: "Products" }
      data: { product: { eq: $product } }
    ) {
      data {
        product
        name
        longDescription
        description
        subtitle
      }
    }
    relatedCases: allAirtable(
      filter: {
        table: { eq: "CaseStudies" }
        data: { productID: { eq: $recordId }, Disable: { ne: true } }
      }
    ) {
      edges {
        node {
          data {
            case
            name
            productID
            clientID
            sportID
            cardImage {
              url
            }
            weight
          }
        }
      }
    }
    sports: allAirtable(filter: { table: { eq: "Sports" } }) {
      ...SportsFragment
    }
    clients: allAirtable(filter: { table: { eq: "Clients" } }) {
      ...ClientsFragment
    }
    products: allAirtable(filter: { table: { eq: "Products" } }) {
      ...ProductsFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_product: allAirtable(
      filter: {
        table: { eq: "StaticContent" }
        data: { page: { eq: "product" } }
      }
    ) {
      ...StaticContentFragment
    }
  }
`

export default props => {
  const { data } = props
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const cases = transformToArray(props.data, "relatedCases")

  const sportMap = transformToMap(props.data, "sports")
  const clientMap = transformToMap(props.data, "clients")
  const productMap = transformToMap(props.data, "products")
  const content = createStaticContent(props.data, "product")

  const product_name = data.product.data.name

  return (
    <PageLayout locations={locations} static_content={content}>
      <Helmet>
        <title>{makeStaticPageTitle(content, product_name)}</title>
      </Helmet>
      <PageContentsWrapper>
        <SectionTitle title={product_name} bg_icon="lineart1" />
        {!_.isEmpty(cases) ? (
          <ItemRows child_limit={0}>
            {_.map(cases, (caseStudy, i) => (
              <CaseStudyCard
                key={i}
                caseStudy={caseStudy}
                clientMap={clientMap}
                productMap={productMap}
                sportMap={sportMap}
              />
            ))}
          </ItemRows>
        ) : (
          <EmptyPage
            title={
              `We're hard at work documenting more of our success stories. ` +
              `Check back soon to see all the exciting things we've been doing with ${product_name}.`
            }
            description=""
          />
        )}
      </PageContentsWrapper>
    </PageLayout>
  )
}
